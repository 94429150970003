import machineAutomation from "../images/offer/automatyka.JPG";
import flatSurfaces from "../images/offer/płaskie.JPG";
import glazing from "../images/offer/przeszklenia.JPG";
import photovoltaics from "../images/offer/fotowoltaika.jfif";
import upholstery from "../images/offer/tapicerki.JPG";
import ozone from "../images/offer/ozon.jpeg";
import landingPageImage from "../images/landingPageImage.png";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import ServicePanel from "./ServicePanel";
import { useTranslation } from "react-i18next";
const LandingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className="min-h-screen flex-col items-center"
    >
      <div className="w-full flex justify-center items-center relative">
        <img
          width={1600}
          className="opacity-70"
          src={landingPageImage}
          alt="landingPageImage"
        />
        <motion.div
          initial={{ opacity: 0.5, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="absolute top-0 left-0 w-full h-full flex flex-row md:flex-col justify-start items-center md:justify-center md:items-start md:gap-0 gap-5 px-[15%]"
        >
          <h1 className="text-white text-xl md:text-4xl font-bold mb-4">
            {t("landingPage.title")}
          </h1>
          <button
            onClick={() => navigate("/offer")}
            className="bg-white rounded-2xl text-black font-semibold px-3 md:px-6 py-1 md:py-3 hover:bg-black hover:text-white"
          >
            {t("landingPage.buttonText")}
          </button>
        </motion.div>
      </div>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.4,
            },
          },
        }}
        className="w-full px-40 py-20 flex justify-center flex-wrap gap-32"
      >
        <ServicePanel
          title={t("landingPage.machineAutomationTitle")}
          photo={machineAutomation}
        />
        <ServicePanel
          title={t("landingPage.flatSurfacesTitle")}
          photo={flatSurfaces}
        />
        <ServicePanel title={t("landingPage.glazingTitle")} photo={glazing} />
        <ServicePanel
          title={t("landingPage.photovoltaicsTitle")}
          photo={photovoltaics}
        />
        <ServicePanel
          title={t("landingPage.upholsteryTitle")}
          photo={upholstery}
        />
        <ServicePanel
          title={t("landingPage.disinfectionTitle")}
          photo={ozone}
        />
      </motion.div>
    </div>
  );
};

export default LandingPage;
