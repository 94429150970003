import { motion } from "framer-motion";

const ServicePanel = ({ title, photo }) => {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
      }}
      className="min-h-96 w-80 bg-servicePanel rounded-2xl shadow-xl flex flex-col text-center justify-start p-5 gap-5 items-center"
    >
      <div className="min-w-80 h-96 overflow-hidden ">
        <motion.img
          whileHover={{ scale: 1.1, opacity: 0.7 }}
          transition={{ duration: 0.3 }}
          className="w-full h-full object-cover"
          src={photo}
          alt={title}
        />
      </div>
      <h2 className="text-xl flex-grow flex justify-center items-center font-semibold text-[#FBC814]">
        {title}
      </h2>
    </motion.div>
  );
};

export default ServicePanel;
