import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import Navbar from "./components/Navbar";
import LandingPage from "./components/LandingPage";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import AboutUs from "./components/AboutUs";
import WhyUs from "./components/WhyUs";
import Offer from "./components/Offer";
import Gallery from "./components/Gallery";
import Languages from "./components/Languages"

function App() {
  return (
    <div className="min-w-full min-h-screen">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/whyUs" element={<WhyUs />} />
          <Route path="/offer" element={<Offer />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </Router>
      <Footer />
      <Languages/>
    </div>
  );
}

export default App;
