import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className=" text-white flex flex-col items-center  gap-20 py-10"
    >
      <div className="text-center">
        <h2 className="text-4xl ">{t("contact.title")}</h2>
      </div>
      <div className="flex flex-col xl:flex-row xl:justify-center xl:items-start gap-36">
        <div className="flex flex-col gap-10 items-center">
          <motion.div
            initial={{ opacity: 0.5, scale: 0.4 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="h-60 w-60 flex justify-center items-center bg-white rounded-full"
          >
            <FaPhoneAlt color="black" size={80} />
          </motion.div>
          <div className="text-center flex flex-col gap-4">
            <h2 className="text-3xl text-white">TELEFON</h2>
            <a href="tel:793546252" className="text-4xl text-[#FBC814]">
              793 546 252
            </a>
            <a href="tel:501521704" className="text-4xl text-[#FBC814]">
              501 521 704
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-10 items-center">
          <motion.div
            initial={{ opacity: 0.5, scale: 0.4 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
            className="h-60 w-60 flex justify-center items-center bg-white rounded-full"
          >
            <MdOutlineMailOutline color="black" size={100} />
          </motion.div>
          <div className="text-center flex flex-col gap-4">
            <h2 className="text-3xl text-white">EMAIL</h2>
            <a
              href="mailto:rosblysk@gmail.com"
              className="text-4xl text-[#FBC814]"
            >
              rosblysk@gmail.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
