import Logo from "../images/Logo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="w-full bg-black min-h-36 flex flex-col justify-center items-center">
      <img width={200} src={Logo} alt="Logo" />
      <div className="md:flex grid grid-cols-3 text-center text-navbarHeader gap-10 my-5 flex-wrap px-10">
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/")}
        >
          {t("navbar.homePage")}
        </h2>
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/aboutUs")}
        >
          {t("navbar.aboutUs")}
        </h2>
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/offer")}
        >
          {t("navbar.offer")}
        </h2>
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/whyUs")}
        >
          {t("navbar.whyUs")}
        </h2>
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/contact")}
        >
          {t("navbar.contact")}
        </h2>
        <h2
          className="cursor-pointer hover:text-white font-semibold"
          onClick={() => navigate("/gallery")}
        >
          {t("navbar.gallery")}
        </h2>
      </div>
    </div>
  );
};

export default Navbar;
