import React from "react";
import ReactCountryFlag from "react-country-flag";
import { setLanguage } from "../translation/i18n";

const Languages = () => {
  const changeLanguage = (lng) => {
    setLanguage(lng);
  };
  return (
    <div className="flex flex-col top-[5%] right-0 fixed z-50">
      <ReactCountryFlag
        onClick={() => changeLanguage("pl")}
        countryCode="PL"
        svg
        style={{
          width: "40px",
          height: "20px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        title="PL"
      />
      <ReactCountryFlag
        onClick={() => changeLanguage("gb")}
        countryCode="GB"
        svg
        style={{
          width: "40px",
          height: "20px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        title="GB"
      />
    </div>
  );
};

export default Languages;
