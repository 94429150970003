import machineAutomation from "../images/offer/automatyka.JPG";
import flatSurfaces from "../images/offer/płaskie.JPG";
import glazing from "../images/offer/przeszklenia.JPG";
import photovoltaics from "../images/offer/fotowoltaika.jfif";
import upholstery from "../images/offer/tapicerki.JPG";
import ozone from "../images/offer/ozon.jpeg";
import OfferPanel from "./OfferPanel";
import { useTranslation } from "react-i18next";

const Offer = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className=" text-white min-h-screen flex flex-col items-center  gap-20 py-10"
    >
      <div className="text-center">
        <h2 className="text-4xl ">{t("offer.title")}</h2>
      </div>
      <OfferPanel
        title={t("offer.machineAutomationTitle")}
        description={t("offer.machineAutomationDescription")}
        photo={machineAutomation}
        side="right"
      />
      <OfferPanel
        title={t("offer.flatSurfacesTitle")}
        description={t("offer.flatSurfacesDescription")}
        photo={flatSurfaces}
        side="left"
      />
      <OfferPanel
        title={t("offer.glazingTitle")}
        description={t("offer.glazingDescription")}
        photo={glazing}
        side="right"
      />
      <OfferPanel
        title={t("offer.photovoltaicsTitle")}
        description={t("offer.photovoltaicsDescription")}
        photo={photovoltaics}
        side="left"
      />
      <OfferPanel
        title={t("offer.upholsteryTitle")}
        description={t("offer.upholsteryDescription")}
        photo={upholstery}
        side="right"
      />
      <OfferPanel
        title={t("offer.disinfectionTitle")}
        description={t("offer.disinfectionDescription")}
        photo={ozone}
        side="left"
      />
    </div>
  );
};

export default Offer;
