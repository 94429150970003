import facebook from "../icons/facebook.svg";
import olx from "../icons/olx.svg";
import instagram from "../icons/instagram.svg";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="min-h-36 flex flex-col justify-center items-center bg-[#3A3A3A] gap-2">
      <h2 className="text-white">{t("footer.title")}</h2>
      <div className="flex justify-center items-center gap-5">
        <a
          href="https://www.facebook.com/p/Ros-B%C5%82ysk-100063631419674/?_rdr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={40}
            className="text-white cursor-pointer duration-200 hover:scale-110"
            src={facebook}
            alt="facebook"
          />
        </a>
        <a
          href="https://www.olx.pl/d/oferta/mycie-okien-i-fotowoltaiki-woda-zdemine-pranie-tapicerek-ozonowanie-CID4371-IDzQE6I.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={50}
            className="text-white cursor-pointer duration-200 hover:scale-110"
            src={olx}
            alt="olx"
          />
        </a>
        <a
          href="https://www.instagram.com/ros_blysk/?locale=fr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            width={40}
            className="text-white cursor-pointer duration-200 hover:scale-110"
            src={instagram}
            alt="instagram"
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
