import aboutUs from "../images/tshirt.jfif";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className=" text-white flex flex-col items-center gap-10 py-10"
    >
      <div className="text-center">
        <h2 className="text-4xl ">{t("aboutUs.title")}</h2>
      </div>
      <div className="w-full xl:w-1/2 text-center flex flex-col md:flex-row justify-center items-center gap-20 px-20 xl:px-0">
        <motion.span
          initial={{ opacity: 0.5, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.3 }}
          className="text-xl"
        >
          {t("aboutUs.description")}
        </motion.span>
        <motion.img
          initial={{ opacity: 0.5, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          width={300}
          src={aboutUs}
        />
      </div>
      <motion.div
        initial={{ opacity: 0.5, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className="text-4xl">{t("aboutUs.ourValues")}</h2>
      </motion.div>
      <motion.div
        initial={{ opacity: 0.5, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-1/2 flex justify-center items-center"
      >
        <span className="text-xl text-center">
        {t("aboutUs.ourValuesDescription")}
        </span>
      </motion.div>
    </div>
  );
};

export default AboutUs;
