import { useTranslation } from "react-i18next";
const importAll = (r) => r.keys().map(r);
const images = importAll(
  require.context("../images/gallery", false, /\.(JPG|jpg|jpeg|jfif|png)$/)
);

const Gallery = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className="text-white min-h-screen flex flex-col items-center gap-20 py-10"
    >
      <div className="text-center">
        <h2 className="text-4xl">{t("gallery.title")}</h2>
      </div>
      <div className="w-3/4 flex gap-10 flex-wrap justify-center">
        {images.map((image, index) => (
          <img
            key={index}
            className="w-80 h-96 object-cover"
            src={image}
            alt={`Gallery image ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Gallery;
