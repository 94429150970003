import { motion } from "framer-motion";

const OfferPanel = ({ title, description, photo, side }) => {
  return (
    <motion.div
    initial={{ opacity: 0, y: -100 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{
      type: "spring",
      stiffness: 300,
      damping: 8,
      duration: 0.5,
    }}
      className="flex flex-col w-full xl:w-1/2 items-center gap-10"
    >
      <div>
        <h2 className="text-xl text-center px-5 font-semibold text-[#FBC814]">{title}</h2>
      </div>
      <div
        className={`flex flex-col ${
          side === "left" ? "md:flex-row" : "md:flex-row-reverse"
        } justify-center items-center gap-10`}
      >
        <img className="w-96 h-96 object-cover rounded-2xl shadow-2xl" src={photo} alt={title} />
        <span className="w-72 text-center">{description}</span>
      </div>
    </motion.div>
  );
};

export default OfferPanel;
