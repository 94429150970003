import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  pl: {
    translation: {
      navbar: {
        homePage: "STRONA GŁÓWNA",
        aboutUs: "O NAS",
        offer: "OFERTA",
        whyUs: "DLACZEGO MY",
        contact: "KONTAKT",
        gallery: "GALERIA",
      },
      landingPage: {
        title: "Twoja firma sprzątająca",
        buttonText: "Zobacz ofertę",
        machineAutomationTitle:"Doczyszczanie automatyki magazynowej",
        flatSurfacesTitle:"Mycie powierzchni płaskich magazynowych",
        glazingTitle:"Mycie przeszkleń",
        photovoltaicsTitle:"Mycie fotowoltaiki",
        upholsteryTitle:"Pranie tapicerek meblowych samochodowych czyszczenie wnętrz aut metodą ekstrakcyjną",
        disinfectionTitle:"Dezynfekcja ozonem",
      },
      contact: {
        title: "Bądźmy w kontakcie",
      },
      gallery: {
        title: "Galeria",
      },
      offer: {
        title: "Oferta",
        machineAutomationTitle:"Doczyszczanie automatyki magazynowej",
        machineAutomationDescription:"Jako specjaliści w dziedzinie czystości przemysłowej, oferujemy profesjonalne doczyszczanie automatyki magazynowej. W obszarach takich jak hale magazynowe, linie produkcyjne czy systemy transportu wewnętrznego, kluczowe jest utrzymanie sprzętu w nienagannej czystości. Nasza firma dysponuje odpowiednim sprzętem oraz środkami chemicznymi, aby zagwarantować pełną skuteczność i bezpieczeństwo przy doczyszczaniu maszyn oraz innych elementów automatyki. Dzięki regularnym pracom konserwacyjnym i czyszczącym, minimalizujemy ryzyko awarii i przedłużamy żywotność sprzętu.",
        flatSurfacesTitle:"Mycie powierzchni płaskich magazynowych",
        flatSurfacesDescription:"Duże powierzchnie płaskie, takie jak podłogi magazynowe, wymagają regularnego, gruntownego czyszczenia, aby zachować nie tylko estetykę, ale również bezpieczeństwo pracy. Nasza firma dysponuje profesjonalnymi urządzeniami czyszczącymi oraz wykwalifikowaną kadrą, co pozwala nam realizować zlecenia w sposób szybki i skuteczny. Korzystamy z dedykowanych środków chemicznych, dostosowanych do różnego rodzaju nawierzchni, co pozwala nam osiągać doskonałe efekty nawet na trudnych do utrzymania w czystości powierzchniach.",
        glazingTitle:"Mycie przeszkleń",
        glazingDescription:"Specjalizujemy się w myciu przeszkleń na wysokości do 16 metrów. Nasza oferta obejmuje zarówno mycie fasad szklanych budynków, jak i czyszczenie okien przemysłowych, które wymagają specjalistycznych rozwiązań.",
        photovoltaicsTitle:"Mycie fotowoltaiki",
        photovoltaicsDescription:"Oferujemy kompleksowe mycie paneli fotowoltaicznych, które, utrzymane w czystości, działają efektywniej i generują więcej energii. Dzięki naszej nowoczesnej technologii i precyzyjnemu podejściu, dbamy o to, aby każdy panel i każda szklana powierzchnia lśniła czystością. Zanieczysczenia na panelach fotowoltaicznych zmniejszają przenikanie promieniowania słonecznego do ogniw, co bezpośrednio wpływa na sprawność całego systemu. W przypadku dużych instalacji komercyjnych i przemysłowych, straty mogą być ogromne, porwadząc do wielotysięcznych start finansowych. Regularne czyszczenie paneli fotowoltaicznych przynajmniej dwa razy do roku jest kluczem do utrzymania ich w optymalnym stanie.",
        upholsteryTitle:"Pranie tapicerki meblowej i samochodowej",
        upholsteryDescription:"Dla klientów indywidualnych i firm oferujemy usługi prania tapicerki meblowej oraz samochodowej. Dzięki wykorzystaniu profesjonalnych środków chemicznych i nowoczesnych maszyn, jesteśmy w stanie skutecznie usuwać nawet najtrudniejsze zabrudzenia, plamy i nieprzyjemne zapachy. Nasze techniki czyszczenia są bezpieczne dla materiałów, a jednocześnie gwarantują głębokie i dokładne czyszczenie, które przywraca tapicerkom pierwotny wygląd.",
        disinfectionTitle:"Dezynfekcja ozonem",
        disinfectionDescription:"Ozonowanie to jedna z najskuteczniejszych metod dezynfekcji, która eliminuje wirusy, bakterie oraz inne szkodliwe mikroorganizmy. Nasza firma oferuje profesjonalne usługi dezynfekcji ozonem, zarówno w pomieszczeniach przemysłowych, biurowych, jak i w domach prywatnych. Ozon jest w pełni naturalnym i ekologicznym środkiem, który skutecznie neutralizuje zanieczyszczenia powietrza i powierzchni, jednocześnie eliminując nieprzyjemne zapachy."
      },
      aboutUs: {
        title: "O nas",
        description:
          "Ros-Błysk to dynamicznie rozwijająca się firma specjalizująca się w kompleksowych usługach czystościowych, dedykowanych zarówno dla przemysłu, jak i dla klientów indywidualnych. Od lat stawiamy na innowacyjność, profesjonalizm i zaufanie, budując swoją pozycję na rynku usług czyszczących. Nasze działania opierają się na zaawansowanych technologiach, które pozwalają nam sprostać nawet najbardziej wymagającym wyzwaniom w zakresie utrzymania czystości i higieny.",
        ourValues: "Nasze wartości",
        ourValuesDescription:"W Ros-Błysk stawiamy na ciągły rozwój technologiczny, co pozwala nam nieustannie podnosić jakość świadczonych usług. Dzięki inwestycjom w nowoczesny sprzęt oraz profesjonalne środki chemiczne, jesteśmy w stanie zapewnić naszym klientom najwyższe standardy czystości. Nasz zespół składa się z wykwalifikowanych pracowników, którzy nieustannie doskonalą swoje umiejętności, aby sprostać rosnącym wymaganiom rynku."
      },
      whyUs: {
        title: "Dlaczego warto wybrać Ros-Błysk?",
        professionalismTitle:"Profesjonalizm i doświadczenie",
        professionalismDescription:"Działamy na rynku od wielu lat, co pozwoliło nam zdobyć zaufanie licznych klientów, zarówno z sektora prywatnego, jak i biznesowego.",
        datesTitle:"Dogodne terminy",
        datesDescription:"Rozumiemy, jak ważna jest elastyczność, dlatego zawsze staramy się dopasować do harmonogramu naszych klientów.",
        organizationTitle:"Dobra organizacja pracy",
        organizationDescription:"Nasza firma cechuje się sprawną logistyką i doskonałą organizacją, co pozwala na szybkie i skuteczne realizowanie zleceń.",
        trustTitle:"Zaufanie i rzetelność",
        trustDescription:"Dewizą naszej firmy jest zaufanie, które budujemy dzięki rzetelnej realizacji każdego zlecenia. Dbamy o transparentność w kontaktach z klientami i zawsze dotrzymujemy ustalonych terminów.",
        invitation:"Zapraszamy do współpracy z Ros-Błysk - Twoim zaufanym partnerem w utrzymaniu czystości i higieny!"
      },
      footer: {
        title: "Obserwuj nas",
      },
    },
  },
  gb: {
    translation: {
      navbar: {
        homePage: "HOME PAGE",
        aboutUs: "ABOUT US",
        offer: "OFFER",
        whyUs: "WHY US",
        contact: "CONTACT",
        gallery: "GALLERY",
      },
      landingPage: {
        title: "Your Cleaning Company",
        buttonText: "See Our Offer",
        machineAutomationTitle: "Warehouse Automation Cleaning",
        flatSurfacesTitle: "Warehouse Flat Surface Cleaning",
        glazingTitle: "Window Cleaning",
        photovoltaicsTitle: "Photovoltaic Panel Cleaning",
        upholsteryTitle: "Furniture and Car Upholstery Cleaning with Extraction Method",
        disinfectionTitle: "Ozone Disinfection",
      },      
      contact: {
        title: "Let's stay in touch",
      },
      gallery: {
        title: "Gallery",
      },
      offer: {
        title: "Offer",
        machineAutomationTitle: "Cleaning of Warehouse Automation",
        machineAutomationDescription: "As specialists in industrial cleanliness, we offer professional cleaning of warehouse automation. In areas such as warehouses, production lines, or internal transport systems, it is crucial to maintain equipment in immaculate cleanliness. Our company has the appropriate equipment and chemicals to ensure full effectiveness and safety in cleaning machines and other automation components. Through regular maintenance and cleaning, we minimize the risk of breakdowns and extend the lifespan of the equipment.",
        flatSurfacesTitle: "Cleaning of Warehouse Flat Surfaces",
        flatSurfacesDescription: "Large flat surfaces, such as warehouse floors, require regular, thorough cleaning to maintain not only aesthetics but also work safety. Our company has professional cleaning devices and a qualified team, which allows us to complete orders quickly and effectively. We use dedicated chemicals suited to various types of surfaces, allowing us to achieve excellent results even on surfaces that are difficult to maintain.",
        glazingTitle: "Glazing Cleaning",
        glazingDescription: "We specialize in cleaning glazing up to 16 meters high. Our offer includes both cleaning glass facades of buildings and industrial windows, which require specialized solutions.",
        photovoltaicsTitle: "Photovoltaic Panel Cleaning",
        photovoltaicsDescription: "We offer comprehensive cleaning of photovoltaic panels, which, when kept clean, work more efficiently and generate more energy. With our modern technology and precise approach, we ensure that every panel and every glass surface shines with cleanliness. Dirt on photovoltaic panels reduces the penetration of sunlight into the cells, directly affecting the efficiency of the entire system. In the case of large commercial and industrial installations, the losses can be enormous, leading to thousands in financial losses. Regular cleaning of photovoltaic panels at least twice a year is key to keeping them in optimal condition.",
        upholsteryTitle: "Furniture and Car Upholstery Cleaning",
        upholsteryDescription: "For individual clients and companies, we offer furniture and car upholstery cleaning services. Thanks to the use of professional chemicals and modern machines, we can effectively remove even the most stubborn stains, dirt, and unpleasant odors. Our cleaning techniques are safe for materials while guaranteeing deep and thorough cleaning, restoring upholstery to its original appearance.",
        disinfectionTitle: "Ozone Disinfection",
        disinfectionDescription: "Ozonation is one of the most effective disinfection methods, which eliminates viruses, bacteria, and other harmful microorganisms. Our company offers professional ozone disinfection services in industrial, office, and private residential spaces. Ozone is a fully natural and ecological agent that effectively neutralizes air and surface contaminants while eliminating unpleasant odors."
      },
      aboutUs: {
        title: "About Us",
        description:
          "We are a dynamically growing company specializing in comprehensive cleaning services for both industrial and individual clients. For years, we have focused on innovation, professionalism, and trust, building our position in the cleaning services market. Our actions are based on advanced technologies that allow us to meet even the most demanding cleanliness and hygiene challenges.",
        ourValues: "Our Values",
        ourValuesDescription:
          "We emphasize continuous technological development, which allows us to constantly improve the quality of our services. Through investments in modern equipment and professional cleaning agents, we are able to provide our clients with the highest standards of cleanliness. Our team consists of qualified employees who continually enhance their skills to meet the growing market demands."
      },   
      whyUs: {
        title: "Why Choose Ros-Błysk?",
        professionalismTitle: "Professionalism and Experience",
        professionalismDescription: "We have been operating in the market for many years, which has allowed us to earn the trust of numerous clients from both the private and business sectors.",
        datesTitle: "Convenient Dates",
        datesDescription: "We understand how important flexibility is, so we always strive to accommodate our clients' schedules.",
        organizationTitle: "Effective Work Organization",
        organizationDescription: "Our company is characterized by efficient logistics and excellent organization, which allows us to execute orders quickly and effectively.",
        trustTitle: "Trust and Reliability",
        trustDescription: "Our company's motto is trust, which we build through reliable execution of every order. We prioritize transparency in our interactions with clients and always meet the agreed deadlines.",
        invitation: "We invite you to collaborate with Ros-Błysk - your trusted partner in maintaining cleanliness and hygiene!"
      },      
      footer: {
        title: "Follow us",
      },
    },
  },
};

const localStorageKey = "selectedLanguage";

const getDefaultLanguage = () => {
  const selectedLanguage = localStorage.getItem(localStorageKey);
  if (selectedLanguage) {
    return selectedLanguage;
  } else {
    return "pl";
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: getDefaultLanguage(),
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export const setLanguage = (language) => {
  i18n.changeLanguage(language);
  localStorage.setItem(localStorageKey, language);
};

export default i18n;
