import { motion } from "framer-motion";

const WhyUs = ({ title, description }) => {
  return (
    <motion.div
      initial={{ opacity: 0.5, scale: 0.4 }}
      animate={{ opacity: 1, scale: [0.4, 1.1, 1] }}
      transition={{ duration: 0.8 }}
      className="w-80 xl:w-[400px] bg-[#FFC403] min-h-64 text-black rounded-2xl shadow-xl flex flex-col text-center justify-start p-5 gap-5 items-center"
    >
      <h2 className="text-md xl:text-xl font-semibold italic text-left first-letter:text-8xl first-letter:font-bold">
        {title}
      </h2>
      <span>{description}</span>
    </motion.div>
  );
};

export default WhyUs;
