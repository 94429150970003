import WhyUsPanel from "./WhyUsPanel";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion"
const WhyUs = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        background: "rgb(0,0,0)",
        background:
          "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(58,58,58,1) 100%)",
      }}
      className=" text-white flex flex-col items-center gap-10 py-10"
    >
      <div className="text-center">
        <h2 className="text-4xl ">{t("whyUs.title")}</h2>
      </div>
      <div className="flex justify-center flex-wrap xl:grid xl:grid-cols-2 xl gap-10">
        <WhyUsPanel
          title={t("whyUs.professionalismTitle")}
          description={t("whyUs.professionalismDescription")}
        />
        <WhyUsPanel
          title={t("whyUs.datesTitle")}
          description={t("whyUs.datesDescription")}
        />
        <WhyUsPanel
          title={t("whyUs.organizationTitle")}
          description={t("whyUs.organizationDescription")}
        />
        <WhyUsPanel
          title={t("whyUs.trustTitle")}
          description={t("whyUs.trustDescription")}
        />
      </div>
      <motion.div
            initial={{ opacity: 0.5, scale: 0.4 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.3 }}
        style={{
          background: "rgb(255,255,255)",
          background:
            "linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(153,153,153,1) 100%)",
        }}
        className="p-3 rounded-2xl shadow-xl text-black mx-5"
      >
        <h2 className="text-center font-semibold">
        {t("whyUs.invitation")}
        </h2>
      </motion.div>
    </div>
  );
};

export default WhyUs;
